import { withAssetPrefix } from '@helpers/assets'

export const WIDTH = {
  fivehundred: 185,
  fiveohthree: 185,
  fourohfourTop: 137,
  fourohfourBottom: 136,
  support: 42
}

export const HEIGHT = {
  fivehundred: 129,
  fiveohthree: 129,
  fourohfourTop: 139,
  fourohfourBottom: 143,
  support: 42
}

export const FALLBACKS = {
  fourohfour_button_text: 'Go to homepage',
  fourohfour_image_alt: 'Page not found',
  fourohfour_text: 'The address is wrong or the page has moved',
  fourohfour_title: 'We can’t find the page you’re looking for',

  fivehundred_image_alt: 'Something went wrong',
  fivehundred_text: 'Try again later or email our support team',
  fivehundred_title: 'Unexpected error, this one’s on us',

  fiveohthree_image_alt: 'Something went wrong',
  fiveohthree_text: 'Try again later or email our support team',
  fiveohthree_title: 'Our bad, service is down',

  support_image_alt: 'Support representative'
}

export const STATUS_CODES = {
  fourohfour: '404',
  fivehundred: '500',
  fiveohthree: '503'
}

export const SUPPORT_EMAIL = 'help@studentbeans.com'

export const FOUR_OH_FOUR_IMAGE_TOP = withAssetPrefix(
  'images/errors/404-unplugged-top.png'
)
export const FOUR_OH_FOUR_IMAGE_BOTTOM = withAssetPrefix(
  'images/errors/404-unplugged-bottom.png'
)
export const FIVE_HUNDRED_IMAGE = withAssetPrefix(
  'images/errors/error-cloud.png'
)
export const SUPPORT_IMAGE = withAssetPrefix('images/errors/support.png')
