import { COUNTRIES, LOCALES } from '@constants/locales'
import { TCountryCode } from '@typings/countries'
import { TCountryConfigItem, TLocale } from '@typings/locales'

export const isCountryUK = (country: TCountryCode): boolean =>
  country === LOCALES.UNITED_KINGDOM

export const isCountryUS = (country: TCountryCode): boolean =>
  country === LOCALES.UNITED_STATES

export const getCountryOptions = (
  country?: TCountryCode
): TCountryConfigItem => {
  const options =
    COUNTRIES[country?.toLowerCase() as keyof typeof COUNTRIES] ??
    COUNTRIES.default
  return options
}

export const getCountryLinks = (
  isGrad: boolean
): Pick<TCountryConfigItem, 'code' | 'fallback'>[] => {
  const countries = Object.keys(COUNTRIES).map((key: TCountryCode) => {
    const country = COUNTRIES[key]

    if (isGrad && !country.hasGraduate) {
      return null
    }

    if (!isGrad && !country.hasCountryLink) {
      return null
    }

    return {
      code: country.code,
      fallback: country.fallback
    }
  })

  return countries.filter(Boolean) as Pick<
    TCountryConfigItem,
    'code' | 'fallback'
  >[]
}

export const getFirstCountryByLocale = (locale: TLocale): TCountryCode => {
  const countryKey = Object.keys(COUNTRIES).find((key: TCountryCode) => {
    const { htmlLang } = getCountryOptions(key)
    return htmlLang === locale
  }) as TCountryCode

  return getCountryOptions(countryKey).code
}
