import { compile } from 'path-to-regexp'
import querystring, { ParsedQuery } from 'query-string'
import root from 'window-or-global'

import { LOCALES } from '@constants/locales'
import {
  ROUTES,
  BLOG_HREF,
  TOP_20_ROUTES,
  BEANSID_URL
} from '@constants/routes'
import { getUrlParamStringFromObject } from '@helpers/auth'
import { getCountryOptions } from '@helpers/countries'
import { TAccountsRouteParams } from '@typings/accounts'
import { TConnectRouteParams } from '@typings/connect'
import { TCountryCode } from '@typings/countries'
import { TParam } from '@typings/page'

export const validateCountry = (params?: TParam): TParam | undefined => {
  const country = params?.country as TCountryCode
  if (!country) return params

  const { fullSupport } = getCountryOptions(country)
  if (fullSupport) return params

  return {
    ...params,
    country: LOCALES.UNITED_KINGDOM
  }
}

export const getNextRoute = (
  routeKey: keyof typeof ROUTES,
  params?: TParam
): string => {
  params = validateCountry(params)
  const route = ROUTES[routeKey]
  if (!route) throw new Error(`Undefined route: '${routeKey}'`)
  return compile(route)(params)
}

export const getPublishRoute = (
  routeKey: keyof typeof ROUTES,
  params?: TParam
): string => {
  params = validateCountry(params)
  const route = ROUTES[routeKey]
  if (!route) throw new Error(`Undefined route: '${routeKey}'`)
  return `${process.env.NEXT_PUBLIC_SB_DOMAIN}${compile(route)(params)}`
}

export const getConnectRoute = (
  routeKey: keyof typeof ROUTES,
  params: TConnectRouteParams & TParam
): string => {
  const route = ROUTES[routeKey]
  return compile(route)(params)
}

export const getAccountsRoute = (
  routeKey: keyof typeof ROUTES,
  params: TAccountsRouteParams & TParam
): string => {
  const route = ROUTES[routeKey]
  return compile(route)(params)
}

export const getTop20Route = (params: { country: TCountryCode }): string => {
  const route = TOP_20_ROUTES[params?.country as keyof typeof TOP_20_ROUTES]
  if (!route) return ''

  return compile(route)(params)
}

export const getBlogHref = (country: keyof typeof BLOG_HREF): string => {
  return BLOG_HREF[country] || ''
}

export const getBeansIdUrl = (country: TCountryCode): string =>
  `${BEANSID_URL}${country}`

export const getHomeHref = (
  country: TCountryCode,
  isGradBeansPage: boolean
): string => {
  if (isGradBeansPage) return getNextRoute('grad.home', { country })

  return getNextRoute('home', { country })
}

export const getCountryLinksHref = (
  isGradBeansPage: boolean,
  params: TParam
): string => {
  if (isGradBeansPage) {
    return getNextRoute('grad.country.links', params)
  }

  return getNextRoute('country.links', params)
}

export const getAllBrandsPageURL = (country: TCountryCode): string => {
  return `${process.env.NEXT_PUBLIC_BRAND_INDEX_PAGE_URL}${country}.json`
}

export const getMyAccountsUrl = (country: TCountryCode): string => {
  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/my/account?client_id=${process.env.NEXT_PUBLIC_AUTH_CLIENT_ID}&country=${country}`
}

export const getMyAccountsUrlFromCRM = (
  query: Partial<{
    [key: string]: string | string[]
  }>
): string => {
  const { country, ...rest } = query

  const urlParams = getUrlParamStringFromObject({
    ...rest,
    client_id: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID,
    country
  })

  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/my/account?${urlParams}`
}

export const getUrl = (): string => root.location?.href ?? ''

export const getUrlQueryObject = (): ParsedQuery<string> => {
  return querystring.parseUrl(getUrl(), { decode: true }).query
}
