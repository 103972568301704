const features = {
  use_kevel_ads_search: true, // FEATURE_TOGGLE 2024/09/04 Turn on kevel ads for search @atlas
  collections_page: false, // FEATURE_TOGGLE 2024/09/04 @rapidash Collections page
  campaigns_page: false, // FEATURE_TOGGLE 2024/09/04 @rapidash Campaigns page
  brand_description_test: true, // FEATURE_TOGGLE 2024/09/04 @rapidash Brand description test on SEO
  zendesk_chat: true, // FEATURE_TOGGLE 2024/09/04 @rapidash Zendesk chat widget
  accounts: false, // FEATURE_TOGGLE 2024/09/04 @eevee Accounts pages
  accounts_authorisation: false, // FEATURE_TOGGLE 2024/09/04 @eevee Accounts authorisation pages
  accounts_verification: false, // FEATURE_TOGGLE 2024/09/04 @eevee Accounts verification pages
  auto_redeem: false, // FEATURE_TOGGLE 2024/09/04 @rapidash Issuance flow
  us_homepage: false, // FEATURE_TOGGLE 2024/09/04 @rapidash US Homepage
  apps_flier: true, // FEATURE_TOGGLE 2024/09/04 @rapidash Apps download links
  freshers_competition: true, // FEATURE_TOGGLE 2024/09/04 @rapidash Freshers competition page
  snowplow_global_contexts: false // FEATURE_TOGGLE 2024/09/09 @mercury Snowplow Global Contexts
}

export default features
