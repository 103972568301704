import { gql } from '@apollo/client'

export const OFFER_TILE_HEADER_FRAGMENT = gql`
  fragment OfferTileHeaderOffer on Offer {
    defaultImageSmall
    brand {
      name
      logo
    }
  }
`
