import { encode } from 'js-base64'
import cookies from 'js-cookie'
import { ReadonlyURLSearchParams } from 'next/navigation'

import {
  client,
  resetAuthHeaders
} from '@root/apollo_clients/beansid_connect/csr'

import {
  LOGIN_AUTH_PATH,
  SIGNUP_AUTH_PATH,
  LOGIN_FALLBACK,
  REGISTER_FALLBACK
} from '@constants/auth'
import { CCGS } from '@constants/closed_consumer_groups'
import { isDevelopment } from '@helpers/environment'
import FeatureToggle from '@helpers/feature_toggle'
import { TConsumerGroups } from '@typings/closed_consumer_groups'
import { TConnectAuthOffer } from '@typings/connect'
import { TCountryCode } from '@typings/countries'
import { TLocale } from '@typings/locales'
import { TTranslateFunction } from '@typings/translations'
import { TUserData } from '@typings/user_data'

import { captureExceptionError } from './capture_exception_error'
import { canSetCookies } from './cookies'
import { getFirstCountryByLocale } from './countries'

export const getLoginUrl = (
  country: TCountryCode,
  pathname: string,
  params?: ReadonlyURLSearchParams | null,
  consumerGroup?: TConsumerGroups
): string => {
  const userReturnTo = getUserReturnTo(pathname, params)
  const authUrlParams = getAuthUrlParams(
    country,
    userReturnTo,
    LOGIN_AUTH_PATH,
    consumerGroup
  )
  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/oauth/authorize?${authUrlParams}`
}

export const getVerificationUrl = (
  country: TCountryCode,
  pathname: string,
  params: ReadonlyURLSearchParams | null,
  ccgToVerify: TConsumerGroups = CCGS.STUDENT,
  expired: boolean
): string => {
  const userReturnTo = getUserReturnTo(pathname, params)
  const urlParams = getUrlParamStringFromObject({
    user_return_to: userReturnTo,
    consumer_group: ccgToVerify
  })

  if (expired)
    return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/verification/expired?${urlParams}`

  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/verification?${urlParams}`
}

export const getReturnToUrl = (
  path?: string,
  searchParams?: URLSearchParams | null
): string => {
  if (!path) return ''

  if (!searchParams) return path

  return `${path}?${searchParams.toString()}`
}

export const getUserReturnTo = (
  pathname?: string,
  params?: URLSearchParams | null
): string => {
  const path = getReturnToUrl(pathname, params)
  const currentUrl = decodeURIComponent(path)

  return encodeURIComponent(`${process.env.NEXT_PUBLIC_SB_DOMAIN}${currentUrl}`)
}

export const getUrlParamStringFromObject = (
  params: { [s: string]: unknown } | ArrayLike<unknown>
): string => {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}

export const getAuthUrlParams = (
  country: TCountryCode,
  userReturnTo: string,
  authPath?: string,
  consumerGroup: TConsumerGroups = CCGS.STUDENT
): string => {
  const urlObject = {
    country,
    response_type: 'code',
    client_id: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID,
    redirect_uri: process.env.NEXT_PUBLIC_AUTH_CALLBACK_URL,
    user_return_to: userReturnTo,
    auth_path: authPath || LOGIN_AUTH_PATH,
    consumer_group: consumerGroup,
    clear_brand_data: 1
  }
  return getUrlParamStringFromObject(urlObject)
}

export const getLoginText = (t: TTranslateFunction): string =>
  t('a_login', { fallback: LOGIN_FALLBACK })

export const getIsLoggedIn = (data: TUserData | undefined): boolean => {
  return !!data?.accountsViewer?.isLoggedIn
}

export const getSignupUrl = (
  country: TCountryCode,
  pathname?: string,
  params?: ReadonlyURLSearchParams | null,
  consumerGroup?: TConsumerGroups
): string => {
  const userReturnTo = getUserReturnTo(pathname, params)
  const authUrlParams = getAuthUrlParams(
    country,
    userReturnTo,
    SIGNUP_AUTH_PATH,
    consumerGroup
  )
  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/oauth/authorize?${authUrlParams}`
}

export const getRegisterText = (t: TTranslateFunction): string =>
  t('d_register', { fallback: REGISTER_FALLBACK })

export const logout = async (
  e: Event,
  country: TCountryCode
): Promise<void> => {
  e.preventDefault()
  cookies.remove('viewer_token', { path: '/' })

  const logoutUrl = `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/authorisation/logout`
  logoutZendesk()

  await fetch(logoutUrl, {
    credentials: 'include'
  })
    .catch((error) => {
      captureExceptionError(error)
    })
    .finally(() => {
      location.assign(`${process.env.NEXT_PUBLIC_SB_DOMAIN}/${country}`)
    })
}

export const getSameSite = (): 'strict' | 'none' => {
  if (isDevelopment()) return 'strict'

  return 'none'
}

export const logoutConnect = async (country: TCountryCode): Promise<void> => {
  // temporary until - https://wearepion.atlassian.net/browse/TPTW-7
  const secure = !isDevelopment()
  const sameSite = getSameSite()

  cookies.remove('connect_viewer_token', {
    path: '/',
    sameSite,
    secure
  })

  resetAuthHeaders()
  await client.resetStore()

  const logoutUrl = `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/${country}/authorisation/logout`

  try {
    await fetch(logoutUrl, {
      credentials: 'include'
    })
  } catch (error) {
    captureExceptionError(error)
  }
}

export const logoutZendesk = (): void | null => {
  if (!FeatureToggle.isEnabled('zendesk_chat')) return null

  window?.zE?.('webWidget', 'logout')
}

export const redirectToLogin = (
  country: TCountryCode,
  pathname: string
): void => {
  const loginUrl = getLoginUrl(country, pathname)
  location.assign(loginUrl)
}

export const getConnectOfferAuthUrlParams = (
  offer?: TConnectAuthOffer
): object => {
  if (!offer) return {}

  const logo = offer?.brand?.logo ?? ''
  const brandName = encode(offer?.brand?.name ?? '')
  const offerTitle = encode(offer?.title ?? '')

  return {
    offer_brand_logo: logo,
    brand_name: brandName,
    offer_title: offerTitle
  }
}

export const getConnectAuthQueryString = (
  country: TCountryCode,
  userReturnTo: string,
  clientId: string,
  locale: TLocale = 'en-gb',
  offer?: TConnectAuthOffer
): string => {
  const urlObject = {
    country,
    response_type: 'code',
    client_id: clientId,
    redirect_uri: process.env.NEXT_PUBLIC_CONNECT_AUTH_CALLBACK_URL as string,
    user_return_to: userReturnTo,
    auth_path: SIGNUP_AUTH_PATH,
    consumer_group: offer?.closedConsumerGroup ?? CCGS.STUDENT,
    medium: 'connect-web',
    language: getFirstCountryByLocale(locale),
    ...getConnectOfferAuthUrlParams(offer)
  }

  return new URLSearchParams(urlObject).toString()
}

export const getConnectUserReturnToParams = (
  params: URLSearchParams
): URLSearchParams => {
  if (canSetCookies()) return params

  const newParams = new URLSearchParams(params)
  newParams.delete('stb_offer_path')
  newParams.delete('validate_iframe')

  return newParams
}

export const getConnectLoginUrl = (
  clientId: string,
  locale: TLocale,
  country: TCountryCode,
  offer: TConnectAuthOffer,
  pathname: string,
  params: URLSearchParams
): string => {
  const userReturnToParams = getConnectUserReturnToParams(params)
  const userReturnTo = getUserReturnTo(pathname, userReturnToParams)

  const authUrlParams = getConnectAuthQueryString(
    country,
    userReturnTo,
    clientId,
    locale,
    offer
  )

  return `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/oauth/authorize?${authUrlParams}`
}

export const buildBody = (params: Record<string, string>): string => {
  const queryString = new URLSearchParams(params).toString()
  return queryString
}

export const getAuthToken = async (
  body: BodyInit
): Promise<{
  accessToken: string
  expiresIn: number
}> => {
  const tokensResponse = await fetch(
    `${process.env.NEXT_PUBLIC_ACCOUNTS_URL}/oauth/token`,
    {
      method: 'POST',
      cache: 'no-cache',
      body,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  )

  const tokens = await tokensResponse.json()
  const { access_token: accessToken, expires_in: expiresIn } = tokens

  return { accessToken, expiresIn }
}
