'use client'

import Polyglot from 'node-polyglot'
import PropTypes from 'prop-types'
import React, { createContext } from 'react'

import { handleMissingKey } from './helpers'
import { II18nPolyglot, II18nProviderProps } from './types'

export const I18nContext = createContext<II18nPolyglot>({
  t: /* istanbul ignore next */ () => '',
  extend: /* istanbul ignore next */ () => {},
  clear: /* istanbul ignore next */ () => {},
  replace: /* istanbul ignore next */ () => {},
  locale: /* istanbul ignore next */ () => '',
  has: /* istanbul ignore next */ () => false,
  unset: /* istanbul ignore next */ () => {},
  translations: {}
})

export const I18nProvider = ({
  children,
  value
}: II18nProviderProps): React.ReactElement => {
  const polyglot = new Polyglot({
    allowMissing: true,
    onMissingKey: handleMissingKey
  })

  polyglot.extend(value)

  return (
    <I18nContext.Provider value={polyglot as II18nPolyglot}>
      {children}
    </I18nContext.Provider>
  )
}

I18nProvider.propTypes = {
  children: PropTypes.any.isRequired,
  value: PropTypes.object.isRequired
}
