import featuresToggles from '@constants/feature_toggles'
import { TFeatureToggle } from '@typings/feature_toggle'

class FeatureToggleClass {
  #featureToggles: typeof featuresToggles | object = {}

  constructor() {
    this.#featureToggles = featuresToggles
  }

  load(features: object, clean = false): void {
    if (clean) this.#featureToggles = {}

    const updatedFeatures = {
      ...this.#featureToggles,
      ...features
    }
    this.#featureToggles = updatedFeatures
  }

  isEnabled(feature: TFeatureToggle): boolean {
    return !!(this.#featureToggles as typeof featuresToggles)[feature]
  }
}

const FeatureToggle = new FeatureToggleClass()

export default FeatureToggle
