import { gql } from '@apollo/client'

export const OFFER_TILE_LABELS_FRAGMENT = gql`
  fragment OfferTileLabelsOffer on Offer {
    contentType
    redemptionClass
    isAdvert
    primaryCategory {
      name
    }
  }
`

export const OFFER_TYPE_LABELS = {
  native_student_discount: 'For students only',
  competitor_student_discount: 'For students only',
  anyone: 'For anyone to use'
}

export const SPONSORED_TEXT_PLACEHOLDER = 'Sponsored'
