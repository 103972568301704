import cookies from 'js-cookie'

import {
  COOKIE_CHECK_LEGACY_NAME,
  COOKIE_CHECK_NAME,
  COOKIE_CHECK_OPTIONS,
  COOKIE_CHECK_VALUE
} from '@constants/cookies'

import { isDevelopment } from './environment'

export const canSetCookies = (): boolean => {
  try {
    setCookieCheck()
    return !!(
      cookies.get(COOKIE_CHECK_NAME) || cookies.get(COOKIE_CHECK_LEGACY_NAME)
    )
  } catch (e) {
    return false
  }
}

export const setCookieCheck = (): void => {
  const secure = !isDevelopment()

  cookies.set(COOKIE_CHECK_LEGACY_NAME, COOKIE_CHECK_VALUE, {
    ...COOKIE_CHECK_OPTIONS,
    secure
  }) as string

  cookies.set(COOKIE_CHECK_NAME, COOKIE_CHECK_VALUE, {
    ...COOKIE_CHECK_OPTIONS,
    secure,
    sameSite: 'none'
  }) as string
}
