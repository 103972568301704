/* istanbul ignore file */
import development from './development'
import production from './production'
import staging from './staging'

const envs = {
  test: development,
  development,
  staging,
  production
}

export default envs[process.env.NEXT_PUBLIC_APP_ENV] || production
