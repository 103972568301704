/* eslint-disable @thebeansgroup/sb/file-naming-convention */
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'cross-fetch'
import cookies from 'js-cookie'

export const getClient = (fetchFunc: typeof fetch): ApolloClient<unknown> => {
  return new ApolloClient({
    link: new HttpLink({
      uri: `${process.env.NEXT_PUBLIC_GRAPHQL_URL}/graphql/v1/query`,
      fetch: fetchFunc,
      headers: {
        authorization: `Bearer ${cookies.get('connect_viewer_token') || 'connect_default_token'}`
      }
    }),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  })
}

export const client = getClient(fetch)

export const clientKeepAlive = getClient((resource, options) => {
  return fetch(resource, {
    ...options,
    keepalive: true
  })
})

export const resetAuthHeaders = (): void => {
  client.setLink(
    new HttpLink({
      uri: `${process.env.NEXT_PUBLIC_GRAPHQL_URL}/graphql/v1/query`,
      fetch,
      headers: {
        authorization: 'connect_default_token'
      }
    })
  )
}
