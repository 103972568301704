import { getNextRoute } from '@helpers/routes'

import { FALLBACKS, WIDTH, HEIGHT, STATUS_CODES } from './constants'

export const getButtonHref = (country) => getNextRoute('home', { country })

export const getImageSize = (type) => ({
  width: WIDTH[type],
  height: HEIGHT[type]
})

export const getAltText = (t, type) =>
  t(`e_${type}_image_alt`, { fallback: FALLBACKS[`${type}_image_alt`] })

export const getStatusCode = (type) => STATUS_CODES[`${type}`] ?? null
