import Script from 'next/script'
import { Fragment } from 'react'

/* eslint-disable @next/next/no-before-interactive-script-outside-document */
const OneTrust = () => (
  <Fragment>
    <Script
      strategy='beforeInteractive'
      src='https://cdn.cookielaw.org/consent/e90905af-6991-4a57-ac4e-8c51d44bfab5/OtAutoBlock.js'
    />
    <Script
      strategy='beforeInteractive'
      src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      data-domain-script='e90905af-6991-4a57-ac4e-8c51d44bfab5'
    />
    <Script
      id='cookie-wrapper'
      strategy='beforeInteractive'
    >
      {'function OptanonWrapper() { }'}
    </Script>
  </Fragment>
)
/* eslint-enable @next/next/no-before-interactive-script-outside-document */

export default OneTrust
