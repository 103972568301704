'use client'

import { useParams } from 'next/navigation'

import { getCountryOptions } from '@helpers/countries'
import { TCountryCode } from '@typings/countries'

export const useCountryCode = (): TCountryCode => {
  const params = useParams<{
    countryCode?: TCountryCode
    country?: TCountryCode
  }>()
  const country =
    params?.countryCode?.toLowerCase() || params?.country?.toLowerCase()
  const { code } = getCountryOptions(country as TCountryCode)

  return code
}
