import { gql } from '@apollo/client'

export const OFFER_TILE_TAGS_FRAGMENT = gql`
  fragment OfferTileTagsOffer on Offer {
    discountEndDate
    expiringSoon
    boosted
    boostedWasText
    boostedExtraText
  }
`

export const ENDS_TODAY = 'Ends today'
export const ENDS_TOMORROW = 'Ends tomorrow'

export const MS_IN_HR = 1000 * 60 * 60

export const WAS_FALLBACK = 'Was'
