var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"qur-ZlqcuWmodpJtpoqNi"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const APP_ENV = process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV

if (['staging', 'production'].includes(APP_ENV)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    allowUrls: [/https?:\/\/((cdn|www)\.)?((staging)\.)?studentbeans\.com/]
  })
}
