import '@styles/globals.css'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { Fragment, useEffect } from 'react'

import ErrorBoundary from '@components/error_boundary'
import GlobalStyles from '@components/global_styles'
import { I18nProvider } from '@components/i18n_provider'
import { useCountryCode } from '@hooks/use_country_code'
import useImpressionListener from '@hooks/use_impression_listener'
import OneTrust from '@scripts/one_trust'
import Survicate from '@scripts/survicate'

const Modal = dynamic(() => import('react-modal'))

const MyApp = ({ Component, pageProps }) => {
  useImpressionListener()
  const country = useCountryCode()

  useEffect(() => {
    Modal?.setAppElement?.(document.getElementById('__next'))
  }, [])

  /* istanbul ignore next */
  const renderSurvicate = () => {
    if (process.env.NEXT_PUBLIC_SURVICATE_ENABLED) {
      return <Survicate />
    }
    return null
  }

  return (
    <Fragment>
      <GlobalStyles />
      <I18nProvider value={pageProps?.translations}>
        <ErrorBoundary
          country={country}
          serverSideError={pageProps?.serverSideError}
        >
          <Component {...pageProps} />
        </ErrorBoundary>
      </I18nProvider>
      <OneTrust />
      {renderSurvicate()}
    </Fragment>
  )
}

MyApp.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.shape({
    translations: PropTypes.object.isRequired,
    serverSideError: PropTypes.any
  }).isRequired
}

export default MyApp
