import { captureException } from '@sentry/nextjs'

export const captureExceptionError = (error: Error | object): string => {
  if (!(error instanceof Error)) {
    const newError = new Error(JSON.stringify(error))

    return captureException(newError)
  }

  return captureException(error)
}
