const features = {
  use_kevel_ads_search: true,
  collections_page: false,
  campaigns_page: false,
  brand_description_test: true,
  zendesk_chat: true,
  accounts: true,
  accounts_authorisation: true,
  accounts_verification: true,
  auto_redeem: false,
  us_homepage: false,
  apps_flier: true,
  freshers_competition: true,
  snowplow_global_contexts: true
}

export default features
