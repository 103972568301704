import { gql } from '@apollo/client'

export const OFFER_TILE_LOGO_NEW_FRAGMENT = gql`
  fragment OfferTileLogoNewOffer on Offer {
    brand {
      name
      logo
    }
  }
`
