import { withScope } from '@sentry/nextjs'

import { TRACK_ADVERT_QUERY } from '@constants/kevel'
import { mutateKeepAlive } from '@helpers/mutate'

import { captureExceptionError } from './capture_exception_error'

export const getKevelTrackingData = (kevelAdvert) => {
  return {
    offerUid: kevelAdvert.offer?.uid,
    trackingUrls: kevelAdvert?.trackingUrls
  }
}

export const trackKevelViewImpressions = async (kevelViewEvents) => {
  const events = kevelViewEvents?.map(getViewEventPayload).filter(Boolean)

  if (!events?.length) return

  try {
    const variables = getMutationVariables(events)

    await mutateKeepAlive({
      mutation: TRACK_ADVERT_QUERY,
      variables
    })
  } catch (error) {
    if (!isNetworkError(error)) {
      throw error
    }

    withScope((scope) => {
      scope.setLevel('info')
      scope.setExtras({ events })
      captureExceptionError(error)
    })
  }
}

export const trackKevelClickImpression = async (kevelClickEvent) => {
  const event = getClickEventPayload(kevelClickEvent)
  if (!event) return

  try {
    const variables = getMutationVariables([event])

    await mutateKeepAlive({
      mutation: TRACK_ADVERT_QUERY,
      variables
    })
  } catch (e) {
    if (!isNetworkError(e)) {
      throw e
    }
  }
}

export const getClickEventPayload = (data) => {
  if (!data?.offerUid || !data?.trackingUrls) return null

  const event = {
    eventType: 'click',
    uid: data.offerUid,
    contentType: 'offer',
    trackingUrls: data.trackingUrls
  }

  return event
}

export const getViewEventPayload = (data) => {
  if (!data?.offerUid || !data?.trackingUrls) return null

  const event = {
    eventType: 'view',
    uid: data.offerUid,
    contentType: 'offer',
    trackingUrls: data.trackingUrls
  }

  return event
}

export const isNetworkError = (e) => !!(e.networkError && !e.extensions)

export const getMutationVariables = (events) => {
  return { input: { events } }
}

export const formatKevelOfferData = (data) => {
  return data?.accountsViewer?.kevelPromoBoxes?.edges
    .map(getOfferData)
    .filter(Boolean)
}

const getOfferData = ({ node }) => {
  if (!node?.offer?.brand) {
    return null
  }

  return {
    ...node.offer,
    kevelTracking: getKevelTrackingData(node)
  }
}

export const getAdvertOffer = (data, advertDataName) => {
  const advert = data?.accountsViewer?.[advertDataName]?.edges?.[0]?.node
  const offer = advert?.offer

  if (!offer) return null

  return {
    node: {
      ...offer,
      kevelTracking: getKevelTrackingData(advert)
    }
  }
}

export const getCombinedAdvertAndOffers = (advert, offers, advertPosition) => {
  if (!advert) return offers

  const edges = [...offers.edges]

  const advertOffersIndex = edges.findIndex((offer) => {
    return offer.node.uid === advert.node.uid
  })

  if (isAdvertInOffers(advertOffersIndex)) {
    moveOfferToAdvertPosition(edges, advert, advertOffersIndex, advertPosition)
  } else {
    insertAdvertAtAdvertPosition(edges, advert, advertPosition)
  }

  return { edges }
}

export const moveOfferToAdvertPosition = (
  edges,
  advert,
  currentIndex,
  advertPosition
) => {
  edges.splice(currentIndex, 1)
  edges.splice(advertPosition, 0, advert)
}

export const insertAdvertAtAdvertPosition = (edges, advert, advertPosition) => {
  edges.splice(advertPosition, 0, advert)
  edges.pop()
}

export const isAdvertInOffers = (index) => index > -1
