export const BASE_COLS_MAP = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12'
}

export const BASE_GAP_MAP = {
  0: 'gap-0',
  1: 'gap-1',
  2: 'gap-2',
  3: 'gap-3',
  4: 'gap-4',
  5: 'gap-5',
  6: 'gap-6',
  7: 'gap-7',
  8: 'gap-8',
  9: 'gap-9',
  10: 'gap-10',
  11: 'gap-11',
  12: 'gap-12'
}

export const BASE_X_GAP_MAP = {
  0: 'gap-x-0',
  1: 'gap-x-1',
  2: 'gap-x-2',
  3: 'gap-x-3',
  4: 'gap-x-4',
  5: 'gap-x-5',
  6: 'gap-x-6',
  7: 'gap-x-7',
  8: 'gap-x-8',
  9: 'gap-x-9',
  10: 'gap-x-10',
  11: 'gap-x-11',
  12: 'gap-x-12'
}

export const BASE_Y_GAP_MAP = {
  0: 'gap-y-0',
  1: 'gap-y-1',
  2: 'gap-y-2',
  3: 'gap-y-3',
  4: 'gap-y-4',
  5: 'gap-y-5',
  6: 'gap-y-6',
  7: 'gap-y-7',
  8: 'gap-y-8',
  9: 'gap-y-9',
  10: 'gap-y-10',
  11: 'gap-y-11',
  12: 'gap-y-12'
}

export const MD_COLS_MAP = {
  1: 'md:grid-cols-1',
  2: 'md:grid-cols-2',
  3: 'md:grid-cols-3',
  4: 'md:grid-cols-4',
  5: 'md:grid-cols-5',
  6: 'md:grid-cols-6',
  7: 'md:grid-cols-7',
  8: 'md:grid-cols-8',
  9: 'md:grid-cols-9',
  10: 'md:grid-cols-10',
  11: 'md:grid-cols-11',
  12: 'md:grid-cols-12'
}

export const MD_GAP_MAP = {
  0: 'md:gap-0',
  1: 'md:gap-1',
  2: 'md:gap-2',
  3: 'md:gap-3',
  4: 'md:gap-4',
  5: 'md:gap-5',
  6: 'md:gap-6',
  7: 'md:gap-7',
  8: 'md:gap-8',
  9: 'md:gap-9',
  10: 'md:gap-10',
  11: 'md:gap-11',
  12: 'md:gap-12'
}

export const MD_X_GAP_MAP = {
  0: 'md:gap-x-0',
  1: 'md:gap-x-1',
  2: 'md:gap-x-2',
  3: 'md:gap-x-3',
  4: 'md:gap-x-4',
  5: 'md:gap-x-5',
  6: 'md:gap-x-6',
  7: 'md:gap-x-7',
  8: 'md:gap-x-8',
  9: 'md:gap-x-9',
  10: 'md:gap-x-10',
  11: 'md:gap-x-11',
  12: 'md:gap-x-12'
}

export const MD_Y_GAP_MAP = {
  0: 'md:gap-y-0',
  1: 'md:gap-y-1',
  2: 'md:gap-y-2',
  3: 'md:gap-y-3',
  4: 'md:gap-y-4',
  5: 'md:gap-y-5',
  6: 'md:gap-y-6',
  7: 'md:gap-y-7',
  8: 'md:gap-y-8',
  9: 'md:gap-y-9',
  10: 'md:gap-y-10',
  11: 'md:gap-y-11',
  12: 'md:gap-y-12'
}

export const LG_COLS_MAP = {
  1: 'lg:grid-cols-1',
  2: 'lg:grid-cols-2',
  3: 'lg:grid-cols-3',
  4: 'lg:grid-cols-4',
  5: 'lg:grid-cols-5',
  6: 'lg:grid-cols-6',
  7: 'lg:grid-cols-7',
  8: 'lg:grid-cols-8',
  9: 'lg:grid-cols-9',
  10: 'lg:grid-cols-10',
  11: 'lg:grid-cols-11',
  12: 'lg:grid-cols-12'
}

export const LG_GAP_MAP = {
  0: 'lg:gap-0',
  1: 'lg:gap-1',
  2: 'lg:gap-2',
  3: 'lg:gap-3',
  4: 'lg:gap-4',
  5: 'lg:gap-5',
  6: 'lg:gap-6',
  7: 'lg:gap-7',
  8: 'lg:gap-8',
  9: 'lg:gap-9',
  10: 'lg:gap-10',
  11: 'lg:gap-11',
  12: 'lg:gap-12'
}

export const LG_X_GAP_MAP = {
  0: 'lg:gap-x-0',
  1: 'lg:gap-x-1',
  2: 'lg:gap-x-2',
  3: 'lg:gap-x-3',
  4: 'lg:gap-x-4',
  5: 'lg:gap-x-5',
  6: 'lg:gap-x-6',
  7: 'lg:gap-x-7',
  8: 'lg:gap-x-8',
  9: 'lg:gap-x-9',
  10: 'lg:gap-x-10',
  11: 'lg:gap-x-11',
  12: 'lg:gap-x-12'
}

export const LG_Y_GAP_MAP = {
  0: 'lg:gap-y-0',
  1: 'lg:gap-y-1',
  2: 'lg:gap-y-2',
  3: 'lg:gap-y-3',
  4: 'lg:gap-y-4',
  5: 'lg:gap-y-5',
  6: 'lg:gap-y-6',
  7: 'lg:gap-y-7',
  8: 'lg:gap-y-8',
  9: 'lg:gap-y-9',
  10: 'lg:gap-y-10',
  11: 'lg:gap-y-11',
  12: 'lg:gap-y-12'
}

export const XL_COLS_MAP = {
  1: 'xl:grid-cols-1',
  2: 'xl:grid-cols-2',
  3: 'xl:grid-cols-3',
  4: 'xl:grid-cols-4',
  5: 'xl:grid-cols-5',
  6: 'xl:grid-cols-6',
  7: 'xl:grid-cols-7',
  8: 'xl:grid-cols-8',
  9: 'xl:grid-cols-9',
  10: 'xl:grid-cols-10',
  11: 'xl:grid-cols-11',
  12: 'xl:grid-cols-12'
}

export const XL_GAP_MAP = {
  0: 'xl:gap-0',
  1: 'xl:gap-1',
  2: 'xl:gap-2',
  3: 'xl:gap-3',
  4: 'xl:gap-4',
  5: 'xl:gap-5',
  6: 'xl:gap-6',
  7: 'xl:gap-7',
  8: 'xl:gap-8',
  9: 'xl:gap-9',
  10: 'xl:gap-10',
  11: 'xl:gap-11',
  12: 'xl:gap-12'
}

export const XL_X_GAP_MAP = {
  0: 'xl:gap-x-0',
  1: 'xl:gap-x-1',
  2: 'xl:gap-x-2',
  3: 'xl:gap-x-3',
  4: 'xl:gap-x-4',
  5: 'xl:gap-x-5',
  6: 'xl:gap-x-6',
  7: 'xl:gap-x-7',
  8: 'xl:gap-x-8',
  9: 'xl:gap-x-9',
  10: 'xl:gap-x-10',
  11: 'xl:gap-x-11',
  12: 'xl:gap-x-12'
}

export const XL_Y_GAP_MAP = {
  0: 'xl:gap-y-0',
  1: 'xl:gap-y-1',
  2: 'xl:gap-y-2',
  3: 'xl:gap-y-3',
  4: 'xl:gap-y-4',
  5: 'xl:gap-y-5',
  6: 'xl:gap-y-6',
  7: 'xl:gap-y-7',
  8: 'xl:gap-y-8',
  9: 'xl:gap-y-9',
  10: 'xl:gap-y-10',
  11: 'xl:gap-y-11',
  12: 'xl:gap-y-12'
}
