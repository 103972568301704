'use client'

import { Global } from '@emotion/react'
import { Fragment } from 'react'

import * as styles from './styles'

const GlobalStyles = () => {
  return (
    <Fragment>
      <Global styles={styles.globalStyles} />
    </Fragment>
  )
}

export default GlobalStyles
