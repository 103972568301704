import Script from 'next/script'

const Survicate = () => (
  <Script
    type='text/javascript'
    src='https://survey.survicate.com/workspaces/2a2d2bc6ae3345f384cbb48a1408f61f/web_surveys.js'
    strategy='lazyOnload'
  />
)

export default Survicate
