import { getClasses } from './helpers'
import { TGridProps } from './types'

const Grid = ({
  cols = 1,
  gap = 0,
  gapX,
  gapY,
  children,
  className,
  ...rest
}: TGridProps): React.ReactElement => {
  return (
    <div
      className={getClasses(cols, gap, gapX, gapY, className)}
      data-testid='grid'
      {...rest}
    >
      {children}
    </div>
  )
}

export default Grid
