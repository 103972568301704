'use client'

import { useContext } from 'react'

import { I18nContext } from '@components/i18n_provider'
import { TTranslateFunction } from '@typings/translations'

export const useI18n = (): {
  t: TTranslateFunction
} => {
  const polyglot = useContext(I18nContext)

  const t: TTranslateFunction = (key, params) => {
    if (!key || !polyglot.has(key)) return params?.fallback ?? ''

    return polyglot.t(key, params)
  }

  return {
    t
  }
}
