import { gql } from '@apollo/client'

import { OFFER_TILE_BRAND_FRAGMENT } from '@components/offer_tile_brand/constants'
import { OFFER_TILE_EXCLUSIVE_FLAG_FRAGMENT } from '@components/offer_tile_exclusive_flag/constants'
import { OFFER_TILE_HEADER_FRAGMENT } from '@components/offer_tile_header/constants'
import { OFFER_TILE_LABELS_FRAGMENT } from '@components/offer_tile_labels/constants'
import { OFFER_TILE_LINK_CONTAINER_FRAGMENT } from '@components/offer_tile_link_container/constants'
import { OFFER_TILE_LOGO_NEW_FRAGMENT } from '@components/offer_tile_logo/constants'
import { OFFER_TILE_TAGS_FRAGMENT } from '@components/offer_tile_tags/constants'
import { OFFER_TILE_TITLE_NEW_FRAGMENT } from '@components/offer_tile_title/constants'

export const OFFER_TILE_NEW_FRAGMENT = gql`
  fragment OfferTileNewOffer on Offer {
    uid
    ...OfferTileLinkContainerOffer
    ...OfferTileExclusiveFlagOffer
    ...OfferTileHeaderOffer
    ...OfferTileTagsOffer
    ...OfferTileTitleNewOffer
    ...OfferTileBrandOffer
    ...OfferTileLabelsOffer
    ...OfferTileLogoNewOffer
  }
  ${OFFER_TILE_LINK_CONTAINER_FRAGMENT}
  ${OFFER_TILE_EXCLUSIVE_FLAG_FRAGMENT}
  ${OFFER_TILE_HEADER_FRAGMENT}
  ${OFFER_TILE_TAGS_FRAGMENT}
  ${OFFER_TILE_TITLE_NEW_FRAGMENT}
  ${OFFER_TILE_BRAND_FRAGMENT}
  ${OFFER_TILE_LABELS_FRAGMENT}
  ${OFFER_TILE_LOGO_NEW_FRAGMENT}
`
