import {
  IMPRESSION_LOAD_SCHEMA_URI,
  IMPRESSION_VIEW_SCHEMA_URI,
  BATCH_EVENT_LIMIT
} from '@constants/impression_tracking'

export const hasData = (data) => Array.isArray(data) && data.length > 0

const removeFirstItemFromArr = (arr) => arr.filter((item, index) => index !== 0)

export const constructSnowplowEventData = (data) => {
  const loadData = getEventData(
    data?.impressionLoad,
    IMPRESSION_LOAD_SCHEMA_URI
  )
  const viewData = getEventData(
    data?.impressionView,
    IMPRESSION_VIEW_SCHEMA_URI
  )
  const events = [...loadData, ...viewData]

  const batchedEvents = batchArray(events, BATCH_EVENT_LIMIT)

  return batchedEvents.map((batch) => {
    return {
      event: batch?.[0],
      contexts: removeFirstItemFromArr(batch)
    }
  })
}

const getEventData = (data, schema) => {
  if (!hasData(data)) return []

  return data.map((item) => {
    return {
      schema,
      data: item
    }
  })
}

export const batchArray = (array, size) => {
  if (array.length <= size) {
    return [array]
  }

  const itemsBeforeLimit = array.slice(0, size)
  const itemsAfterLimit = batchArray(array.slice(size), size)

  return [itemsBeforeLimit, ...itemsAfterLimit]
}
