export const ROUTES = {
  apps: '/:country/about/apps',
  about: '/:country/about',
  'how.it.works': '/:country/how-it-works',
  'freshers.competition': '/:country/freshers-competition',
  home: '/:country',
  'home.base': '/',
  'brand.show': '/student-discount/:country/:brand',
  'category.show': '/student-discount/:country/cats/:category',
  'category.all': '/student-discount/:country/all',
  'campaign.show': '/student-discount/:country/campaign-plus/:slug',
  'collection.show': '/student-discount/:country/collections/:collection',
  impressum: '/:country/impressum',
  'footer.advertise': 'https\\://partner.studentbeans.com/',
  'footer.careers': 'https\\://partner.studentbeans.com/about-us/careers/',
  'footer.verification':
    'https\\://partner.studentbeans.com/brand-solutions/student-verification',
  'footer.marketing':
    'https\\://partner.studentbeans.com/brand-solutions/student-marketing',
  'footer.research':
    'https\\://partner.studentbeans.com/brand-solutions/student-research',
  'footer.about': '/:country/about',
  'footer.how_it_works': '/:country/how-it-works',
  'footer.privacy': 'https\\://accounts.studentbeans.com/:country/info/privacy',
  'footer.terms': 'https\\://accounts.studentbeans.com/:country/info/terms',
  'id.show': '/id/:country',
  'footer.chrome_extension':
    'https\\://chrome.google.com/webstore/detail/student-beans/pdmhehfogekmpmdoemhabjpaiadagpgp',
  'footer.categories': '/student-discount/:country/cats',
  'footer.brands': '/student-discount/:country/brands',
  'footer.collections': '/student-discount/:country/collections',
  'footer.campaigns': '/student-discount/:country/campaigns',
  'footer.faqs': 'https\\://help.studentbeans.com/hc/:locale',
  graduate:
    '/gradbeans/:country\\?utm_source=SB&utm_medium=web&utm_campaign=:campaign',
  'country.links': '/:country/country-links',
  'blog.home': '/blog/:country',
  'blog.article': '/blog/:country/:slug',
  'recommended.show': '/student-discount/:country/recommended-for-you',
  'sbid.support': 'https\\://help.studentbeans.com/hc/:country',
  'grad.home': '/graduate-discount/:country',
  'grad.home.section': '/graduate-discount/:country#:section',
  'grad.country.links': '/graduate-discount/:country/country-links',
  'grad.brand': '/graduate-discount/:country/:brand',
  trending: '/:country/trending-discounts',
  'bext.auth.success': '/:country/browser-extension/auth/success',
  'connect.not_found':
    '/:locale/:countryCode/beansid-connect/:implementation/:slug/not-found',
  'connect.single_offer':
    '/:locale/:countryCode/beansid-connect/:implementation/:slug/:ccg/:offerUid',
  'connect.multi_offer':
    '/:locale/:countryCode/beansid-connect/:implementation/:slug/:ccg',
  'connect.multi_ccg':
    '/:locale/:countryCode/beansid-connect/:implementation/:slug/multi-ccg',
  'accounts.auth.signup':
    '/:locale/:countryCode/accounts/authorisation/sign-up',
  'accounts.auth.login': '/:locale/:countryCode/accounts/authorisation/login',
  'accounts.auth.your_details':
    '/:locale/:countryCode/accounts/authorisation/your-details',
  'accounts.auth.reset_password':
    '/:locale/:countryCode/accounts/authorisation/reset-password',
  'accounts.auth.email_preferences':
    '/:locale/:countryCode/accounts/authorisation/email-preferences'
}

const COLLECTIONS_ROUTE = '/student-discount/:country/collections'

export const TOP_20_ROUTES = {
  uk: COLLECTIONS_ROUTE + '/top-20-student-discounts',
  us: COLLECTIONS_ROUTE + '/top-20-student-discounts-us',
  au: COLLECTIONS_ROUTE + '/popular-student-discounts'
}

export const BLOG_HREF = {
  us: 'https://www.studentbeans.com/blog/us/',
  au: 'https://aublog.studentbeans.com/',
  uk: 'https://studentbeans.com/blog/uk'
}

export const BEANSID_URL = 'https://beansid.com/'
