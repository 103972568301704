export const FIVE_HUNDRED = 'fivehundred'
export const FOUR_OH_FOUR = 'fourohfour'
export const FIVE_OH_THREE = 'fiveohthree'

export const FIVE_HUNDRED_PAGE_TITLE = '500 - something went wrong'
export const FIVE_HUNDRED_PAGE_DESCRIPTION =
  'Something has gone wrong trying to fetch this page, please try again or get in touch.'

export const FOUR_OH_FOUR_PAGE_TITLE = '404 - this page no longer exists'
export const FOUR_OH_FOUR_PAGE_DESCRIPTION =
  'This page has been removed and no longer exists, sorry about that.'
