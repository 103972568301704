export const LOCALES = {
  AUSTRALIA: 'au',
  AUSTRIA: 'at',
  BELGIUM: 'be',
  CANADA: 'ca',
  CHINA: 'cn',
  CROATIA: 'hr',
  DENMARK: 'dk',
  FINLAND: 'fi',
  FRANCE: 'fr',
  GERMANY: 'de',
  HONG_KONG: 'hk',
  INDIA: 'in',
  IRELAND: 'ie',
  ITALY: 'it',
  MEXICO: 'mx',
  NETHERLANDS: 'nl',
  NEW_ZEALAND: 'nz',
  NIGERIA: 'ng',
  NORWAY: 'no',
  POLAND: 'pl',
  PORTUGAL: 'pt',
  ROMANIA: 'ro',
  SINGAPORE: 'sg',
  SPAIN: 'es',
  SWEDEN: 'se',
  SWITZERLAND: 'ch',
  UNITED_KINGDOM: 'uk',
  UNITED_STATES: 'us'
} as const

const DEFAULT_LOCALE = 'en'
const DEFAULT_ZENDESK = 'en-us'

/*
  In order to avoid needed a bunch of documentation to add a new country to web
  we have centralised and explained the config here so it lives with the code
  and nothing is missed when a new country is added

  // The country code from the URL e.g. https://www.studentbeans/com/au
  [code: String]: {
    //  The country code from the URL e.g. https://www.studentbeans/com/au
    code: String    

    //  The name of the country in English as a fallback
    //  if we the country name is used in a translation
    //  we need to store the fallback here otherwise we won't
    //  know what it is
    fallback: String

    //  We have the concept of full/partial supported countries.
    //  Partially supported countries brand pages will load and work,
    //  but the links on the page will redirect to UK.
    //  Pages like the homepage will 404 because it doesn't exist,
    //  without this we spawn a chain of broken pages in Google
    //  which we are penalised for
    fullSupport: Boolean

    //  Not every country has the SB app.
    //  We use this to hide app promos so we are not redirecting to a dead end
    hasApp: Boolean

    //  Not every country has the SB browser extension.
    //  We use this to hide browser extension promos so we are not redirecting
    to a dead end
    hasBrowserExtension: Boolean

    //  Legacy code
    //  Not every country has a link on the country links page (https://www.studentbeans.com/uk/country-links)
    //  e.g. Hong Kong was added a bit hackily, can hopefully be refactored soon
    hasCountryLink: Boolean

    //  Grad beans only has a few supported countries (https://www.studentbeans.com/graduate-discount/uk/country-links)
    //  This lets us control this in a single place here
    hasGraduate: Boolean

    //  BeansiD only has a few supported countries (https://www.beansid.com/uk/country-links)
    //  This lets us control this in a single place here
    hasBeansId: Boolean

    // Kevel Promo Boxes are only supported in a few countries right now
    // This toggle controls which countries they are enabled in
    hasKevelPromoBoxes: Boolean

    // Controls whether the zendesk chat widget is available for this country or not
    hasZendeskChat: Boolean

    //  Used by search engine crawlers 
    htmlLang: String ISO Code https://www.w3schools.com/tags/ref_language_codes.asp

    //  It is common to feature toggle by English speaking countries only
    //  We need to control this here in one place, so that we don't need to hunt helpers
    //  down to add in a new country
    isEnglishSpeaking: Boolean

    //  The name of the transifex file to load for this country
    //  https://git.thebeansgroup.com/studentbeans/web/-/tree/master/config/locales/internationalisation
    locale: String

    //  Zendesk is the third party site we use for the support portal https://help.studentbeans.com/hc/en-us
    //  It has a separate locale list
    zendesk: String
  },
*/

export const COUNTRIES = {
  [LOCALES.AUSTRALIA]: {
    code: LOCALES.AUSTRALIA,
    fallback: 'Australia',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: true,
    hasBeansId: true,
    hasKevelPromoBoxes: true,
    hasZendeskChat: false,
    htmlLang: 'en-au',
    isEnglishSpeaking: true,
    locale: 'en',
    zendesk: DEFAULT_ZENDESK,
    currency: 'AUD'
  },
  [LOCALES.AUSTRIA]: {
    code: LOCALES.AUSTRIA,
    fallback: 'Austria',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'de-at',
    isEnglishSpeaking: false,
    locale: 'de',
    zendesk: DEFAULT_ZENDESK,
    currency: 'EUR'
  },
  [LOCALES.BELGIUM]: {
    code: LOCALES.BELGIUM,
    fallback: 'Belgium',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'nl-be',
    isEnglishSpeaking: false,
    locale: 'nl',
    zendesk: 'nl-be',
    currency: 'EUR'
  },
  [LOCALES.CANADA]: {
    code: LOCALES.CANADA,
    fallback: 'Canada',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: true,
    hasBeansId: true,
    hasKevelPromoBoxes: true,
    hasZendeskChat: false,
    htmlLang: 'en-ca',
    isEnglishSpeaking: true,
    locale: 'en',
    zendesk: 'en-us',
    currency: 'CAD'
  },
  [LOCALES.CHINA]: {
    code: LOCALES.CHINA,
    fallback: 'China',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'zh-cn',
    isEnglishSpeaking: false,
    locale: 'zh-Hans',
    zendesk: 'zh-cn',
    currency: 'CNY'
  },
  [LOCALES.CROATIA]: {
    code: LOCALES.CROATIA,
    fallback: 'Croatia',
    fullSupport: false,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: false,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'hr-hr',
    isEnglishSpeaking: false,
    locale: DEFAULT_LOCALE,
    zendesk: DEFAULT_ZENDESK,
    currency: 'HRK'
  },
  [LOCALES.DENMARK]: {
    code: LOCALES.DENMARK,
    fallback: 'Denmark',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'da-dk',
    isEnglishSpeaking: false,
    locale: 'da',
    zendesk: 'da',
    currency: 'DKK'
  },
  [LOCALES.FINLAND]: {
    code: LOCALES.FINLAND,
    fallback: 'Finland',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'fi-fi',
    isEnglishSpeaking: false,
    locale: 'fi',
    zendesk: DEFAULT_ZENDESK,
    currency: 'EUR'
  },
  [LOCALES.FRANCE]: {
    code: LOCALES.FRANCE,
    fallback: 'France',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: true,
    hasZendeskChat: false,
    htmlLang: 'fr-fr',
    isEnglishSpeaking: false,
    locale: 'fr',
    zendesk: 'fr',
    currency: 'EUR'
  },
  [LOCALES.GERMANY]: {
    code: LOCALES.GERMANY,
    fallback: 'Germany',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: true,
    hasZendeskChat: false,
    htmlLang: 'de-de',
    isEnglishSpeaking: false,
    locale: 'de',
    zendesk: 'de',
    currency: 'EUR'
  },
  [LOCALES.HONG_KONG]: {
    code: LOCALES.HONG_KONG,
    fallback: 'Hong Kong S.A.R.',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'zh-hk',
    isEnglishSpeaking: false,
    locale: 'zh-Hant',
    zendesk: DEFAULT_ZENDESK,
    currency: 'HKD'
  },
  [LOCALES.INDIA]: {
    code: LOCALES.INDIA,
    fallback: 'India',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'en',
    isEnglishSpeaking: true,
    locale: DEFAULT_LOCALE,
    zendesk: DEFAULT_ZENDESK,
    currency: 'INR'
  },
  [LOCALES.IRELAND]: {
    code: LOCALES.IRELAND,
    fallback: 'Ireland',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: true,
    hasKevelPromoBoxes: true,
    hasZendeskChat: false,
    htmlLang: 'en-ie',
    isEnglishSpeaking: true,
    locale: 'en',
    zendesk: DEFAULT_ZENDESK,
    currency: 'EUR'
  },
  [LOCALES.ITALY]: {
    code: LOCALES.ITALY,
    fallback: 'Italy',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'it-it',
    isEnglishSpeaking: false,
    locale: 'it',
    zendesk: 'it',
    currency: 'EUR'
  },
  [LOCALES.MEXICO]: {
    code: LOCALES.MEXICO,
    fallback: 'Mexico',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'es-mx',
    isEnglishSpeaking: false,
    locale: 'es',
    zendesk: DEFAULT_ZENDESK,
    currency: 'MXN'
  },
  [LOCALES.NETHERLANDS]: {
    code: LOCALES.NETHERLANDS,
    fallback: 'Netherlands',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: true,
    hasZendeskChat: false,
    htmlLang: 'nl-nl',
    isEnglishSpeaking: false,
    locale: 'nl',
    zendesk: 'nl',
    currency: 'EUR'
  },
  [LOCALES.NEW_ZEALAND]: {
    code: LOCALES.NEW_ZEALAND,
    fallback: 'New Zealand',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: true,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'en-nz',
    isEnglishSpeaking: true,
    locale: 'en',
    zendesk: DEFAULT_ZENDESK,
    currency: 'NZD'
  },
  [LOCALES.NIGERIA]: {
    code: LOCALES.NIGERIA,
    fallback: 'Nigeria',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'en',
    isEnglishSpeaking: true,
    locale: DEFAULT_LOCALE,
    zendesk: DEFAULT_ZENDESK,
    currency: 'NGN'
  },
  [LOCALES.NORWAY]: {
    code: LOCALES.NORWAY,
    fallback: 'Norway',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'no-no',
    isEnglishSpeaking: false,
    locale: 'nn',
    zendesk: DEFAULT_ZENDESK,
    currency: 'NOK'
  },
  [LOCALES.POLAND]: {
    code: LOCALES.POLAND,
    fallback: 'Poland',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'pl-pl',
    isEnglishSpeaking: false,
    locale: 'pl',
    zendesk: 'pl',
    currency: 'PLN'
  },
  [LOCALES.PORTUGAL]: {
    code: LOCALES.PORTUGAL,
    fallback: 'Portugal',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'pt-pt',
    isEnglishSpeaking: false,
    locale: 'pt',
    zendesk: 'pt',
    currency: 'EUR'
  },
  [LOCALES.ROMANIA]: {
    code: LOCALES.ROMANIA,
    fallback: 'Romania',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'ro-ro',
    isEnglishSpeaking: false,
    locale: 'ro',
    zendesk: DEFAULT_ZENDESK,
    currency: 'RON'
  },
  [LOCALES.SINGAPORE]: {
    code: LOCALES.SINGAPORE,
    fallback: 'Singapore',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'en',
    isEnglishSpeaking: true,
    locale: DEFAULT_LOCALE,
    zendesk: DEFAULT_ZENDESK,
    currency: 'SGD'
  },
  [LOCALES.SPAIN]: {
    code: LOCALES.SPAIN,
    fallback: 'Spain',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: true,
    hasZendeskChat: false,
    htmlLang: 'es-es',
    isEnglishSpeaking: false,
    locale: 'es',
    zendesk: 'es',
    currency: 'EUR'
  },
  [LOCALES.SWEDEN]: {
    code: LOCALES.SWEDEN,
    fallback: 'Sweden',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'sv-se',
    isEnglishSpeaking: false,
    locale: 'sv',
    zendesk: 'sv',
    currency: 'SEK'
  },
  [LOCALES.SWITZERLAND]: {
    code: LOCALES.SWITZERLAND,
    fallback: 'Switzerland',
    fullSupport: true,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: true,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'de-ch',
    isEnglishSpeaking: false,
    locale: 'de',
    zendesk: 'de',
    currency: 'CHF'
  },
  [LOCALES.UNITED_KINGDOM]: {
    code: LOCALES.UNITED_KINGDOM,
    fallback: 'United Kingdom',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: true,
    hasBeansId: true,
    hasKevelPromoBoxes: true,
    hasZendeskChat: true,
    htmlLang: 'en-gb',
    isEnglishSpeaking: true,
    locale: 'en-GB',
    zendesk: DEFAULT_ZENDESK,
    currency: 'GBP'
  },
  [LOCALES.UNITED_STATES]: {
    code: LOCALES.UNITED_STATES,
    fallback: 'United States',
    fullSupport: true,
    hasApp: true,
    hasBrowserExtension: true,
    hasCountryLink: true,
    hasGraduate: true,
    hasBeansId: true,
    hasKevelPromoBoxes: true,
    hasZendeskChat: true,
    htmlLang: 'en-us',
    isEnglishSpeaking: true,
    locale: 'en_US',
    zendesk: 'en-us',
    currency: 'USD'
  },
  default: {
    code: LOCALES.UNITED_KINGDOM,
    fallback: '',
    fullSupport: false,
    hasApp: false,
    hasBrowserExtension: false,
    hasCountryLink: false,
    hasGraduate: false,
    hasBeansId: false,
    hasKevelPromoBoxes: false,
    hasZendeskChat: false,
    htmlLang: 'en',
    isEnglishSpeaking: true,
    locale: 'en',
    zendesk: 'en-us',
    currency: 'GBP'
  }
} as const
