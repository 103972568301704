import { css } from '@emotion/react'

import { withAssetPrefix } from '@helpers/assets'

export const globalStyles = css`
  @font-face {
    font-family: 'Gibson';
    src: url(${withAssetPrefix('fonts/gibson_regular.woff2')}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Gibson Light';
    src: url(${withAssetPrefix('fonts/gibson_light.woff2')}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Gibson SemiBold';
    src: url(${withAssetPrefix('fonts/gibson_semibold.woff2')}) format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Gibson Bold';
    src: url(${withAssetPrefix('fonts/gibson_bold.woff2')}) format('woff2');
    font-display: swap;
  }
`
