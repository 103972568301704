import { gql } from '@apollo/client'

export const OFFER_TILE_TITLE_NEW_FRAGMENT = gql`
  fragment OfferTileTitleNewOffer on Offer {
    title
    expired
  }
`

export const OFFER_TILE_EXPIRED = 'This discount has expired'
