'use client'

import { withScope } from '@sentry/nextjs'
import PropTypes from 'prop-types'
import { Component } from 'react'

import ErrorMessage from '@components/error_message'
import { FIVE_HUNDRED } from '@constants/errors'
import { LOCALES } from '@constants/locales'
import { captureExceptionError } from '@helpers/capture_exception_error'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    withScope((scope) => {
      scope.setExtras(errorInfo)
      scope.setTag('sb_handler', 'ErrorBoundary')
      captureExceptionError(error)
    })
  }

  render() {
    if (!this.state.hasError && !this.props.serverSideError)
      return this.props.children

    return (
      <ErrorMessage
        type={FIVE_HUNDRED}
        country={this.props.country}
      />
    )
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
  country: PropTypes.string.isRequired,
  serverSideError: PropTypes.any
}

ErrorBoundary.defaultProps = {
  country: LOCALES.UNITED_KINGDOM,
  serverSideError: null
}

export default ErrorBoundary
