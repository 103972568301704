import root from 'window-or-global'

import {
  setup,
  trackPageView as tptTrackPageView,
  trackStructEvent as tptTrackStructEvent,
  trackSelfDescribingEvent as tptTrackSelfDescribingEvent,
  setUserId,
  addGlobalContexts
} from '@thebeansgroup/third_party_tracking/snowplow'

import { SNOWPLOW_IS_SETUP } from '@constants/snowplow'
import { getSnowplowAppId } from '@helpers/snowplow'
import { TSnowplowApplication } from '@typings/snowplow'
import { ICustomContext } from '@typings/third_party_tracking'

export const setupSnowplow = (
  userId: string | null,
  application: TSnowplowApplication,
  globalContexts: ICustomContext[] = []
): void => {
  setup(
    process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_ENDPOINT,
    getSnowplowAppId(application),
    process.env.NEXT_PUBLIC_SNOWPLOW_COOKIE_DOMAIN
  )
  addGlobalContexts([...globalContexts])
  setUserId(userId)
  document.dispatchEvent(new root.CustomEvent(SNOWPLOW_IS_SETUP))
}

export const trackPageView = (
  title: string | null = null,
  customContexts: ICustomContext[] = []
): void => {
  tptTrackPageView({ title, context: customContexts })
}

export const trackStructEvent = (
  category: string,
  action: string,
  label: string,
  property: string,
  value: string | null
): void => {
  tptTrackStructEvent({
    category,
    action,
    label,
    property,
    value
  })
}

export const trackUnstructEvent = (
  schema: string,
  data: Record<string, unknown>,
  contexts?: ICustomContext[]
): void => {
  tptTrackSelfDescribingEvent({
    event: {
      schema,
      data
    },
    context: contexts
  })
}
