/* eslint-disable @thebeansgroup/sb/safe-image */
import Image from 'next/image'

import { NullableReactElement } from '@typings/utils'

import { TSafeImageProps } from './types'
const SafeImage = ({
  css,
  alt,
  src,
  height = undefined,
  width = undefined,
  style = undefined,
  fill = false,
  priority = false,
  testId,
  ...rest
}: TSafeImageProps): NullableReactElement => {
  if (!src) return null

  return (
    <Image
      className={css}
      alt={alt}
      src={src}
      height={height}
      width={width}
      style={style}
      fill={fill}
      unoptimized
      priority={priority}
      data-testid={testId}
      {...rest}
    />
  )
}

export default SafeImage
