'use client'

import PropTypes from 'prop-types'
import { Fragment } from 'react'

import Button from '@thebeansgroup/ui/button'
import Typography from '@thebeansgroup/ui/typography'
import TextLink from '@thebeansgroup/ui_ctas/text_link'

import Grid from '@components/grid'
import SafeImage from '@components/safe_image'
import { FIVE_HUNDRED, FIVE_OH_THREE, FOUR_OH_FOUR } from '@constants/errors'
import { useI18n } from '@hooks/use_i18n'

import {
  FALLBACKS,
  FIVE_HUNDRED_IMAGE,
  FOUR_OH_FOUR_IMAGE_BOTTOM,
  FOUR_OH_FOUR_IMAGE_TOP,
  SUPPORT_EMAIL,
  SUPPORT_IMAGE
} from './constants'
import {
  getButtonHref,
  getImageSize,
  getAltText,
  getStatusCode
} from './helpers'

const ErrorMessage = ({ type, country }) => {
  const { t } = useI18n()

  const renderButton = () => {
    if (type !== FOUR_OH_FOUR) return null

    return (
      <Button
        component='a'
        href={getButtonHref(country)}
        size='large'
      >
        {t(`a_${type}_button_text`, {
          fallback: FALLBACKS[`${type}_button_text`]
        })}
      </Button>
    )
  }

  const renderTitle = () => (
    <Fragment>
      <Typography
        component='h1'
        variant='uber1'
        fontWeight='bold'
        className='text-lavender-500'
      >
        {getStatusCode(type)}
      </Typography>
      <Typography
        component='h2'
        variant='title1'
        fontWeight='semibold'
      >
        {t(`e_${type}_title`, { fallback: FALLBACKS[`${type}_title`] })}
      </Typography>
    </Fragment>
  )

  const renderText = () => (
    <Typography className='py-5'>
      {t(`m_${type}_text`, { fallback: FALLBACKS[`${type}_text`] })}
    </Typography>
  )

  const render404Images = () => (
    <div className='flex justify-center'>
      <div className='relative w-[300px] h-[150px]'>
        <div className='absolute top-[15px] right-[42%] animate-four-oh-four-bottom'>
          <SafeImage
            alt={getAltText(t, type)}
            src={FOUR_OH_FOUR_IMAGE_BOTTOM}
            width={getImageSize(`${type}Bottom`).width}
            height={getImageSize(`${type}Bottom`).height}
          />
        </div>
        <div className='absolute bottom-[15px] left-[42%] animate-four-oh-four-top'>
          <SafeImage
            alt={getAltText(t, type)}
            src={FOUR_OH_FOUR_IMAGE_TOP}
            width={getImageSize(`${type}Top`).width}
            height={getImageSize(`${type}Top`).height}
          />
        </div>
      </div>
    </div>
  )

  const renderErrorCloud = () => (
    <div className='flex justify-center relative animate-hover'>
      <SafeImage
        alt={getAltText(t, type)}
        src={FIVE_HUNDRED_IMAGE}
        width={getImageSize(type).width}
        height={getImageSize(type).height}
      />
    </div>
  )

  const renderImage = () => {
    if (type === FOUR_OH_FOUR) return render404Images()

    return renderErrorCloud()
  }

  const renderSupport = () => {
    if (type === FOUR_OH_FOUR) return null

    return (
      <div className='flex justify-center items-center gap-[16px]'>
        <div className='size-[42px] rounded-full overflow-hidden'>
          <SafeImage
            alt={t('d_support_representative', {
              fallback: FALLBACKS.support_image_alt
            })}
            src={SUPPORT_IMAGE}
            width={getImageSize('support').width}
            height={getImageSize('support').height}
          />
        </div>
        <TextLink
          href={`mailto:${SUPPORT_EMAIL}`}
          colour='huckleberry'
          text={SUPPORT_EMAIL}
        />
      </div>
    )
  }

  return (
    <Grid className='flex-center flex-col max-w-[625px] mx-auto py-8 text-center lg:pt-[150px]'>
      {renderImage()}
      {renderTitle()}
      {renderText()}
      {renderButton()}
      {renderSupport()}
    </Grid>
  )
}

ErrorMessage.propTypes = {
  type: PropTypes.oneOf([FIVE_OH_THREE, FIVE_HUNDRED, FOUR_OH_FOUR]).isRequired,
  country: PropTypes.string.isRequired
}

export default ErrorMessage
