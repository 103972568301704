import clsx from 'clsx'

import { Nullable } from '@typings/utils'

import {
  BASE_COLS_MAP,
  BASE_GAP_MAP,
  BASE_X_GAP_MAP,
  BASE_Y_GAP_MAP,
  LG_COLS_MAP,
  LG_GAP_MAP,
  LG_X_GAP_MAP,
  LG_Y_GAP_MAP,
  MD_COLS_MAP,
  MD_GAP_MAP,
  MD_X_GAP_MAP,
  MD_Y_GAP_MAP,
  XL_COLS_MAP,
  XL_GAP_MAP,
  XL_X_GAP_MAP,
  XL_Y_GAP_MAP
} from './constants'
import { TBreakpoints } from './types'

export const getColClasses = (cols: TBreakpoints | number): string => {
  if (typeof cols === 'number')
    return BASE_COLS_MAP?.[cols as keyof typeof BASE_COLS_MAP]

  return clsx(
    BASE_COLS_MAP?.[cols?.initial as keyof typeof BASE_COLS_MAP],
    MD_COLS_MAP?.[cols?.md as keyof typeof BASE_COLS_MAP],
    LG_COLS_MAP?.[cols?.lg as keyof typeof BASE_COLS_MAP],
    XL_COLS_MAP?.[cols?.xl as keyof typeof BASE_COLS_MAP]
  )
}

export const getGapClasses = (gap: TBreakpoints | number): string => {
  if (typeof gap === 'number')
    return BASE_GAP_MAP?.[gap as keyof typeof BASE_COLS_MAP]

  return clsx(
    BASE_GAP_MAP?.[gap?.initial as keyof typeof BASE_COLS_MAP],
    MD_GAP_MAP?.[gap?.md as keyof typeof BASE_COLS_MAP],
    LG_GAP_MAP?.[gap?.lg as keyof typeof BASE_COLS_MAP],
    XL_GAP_MAP?.[gap?.xl as keyof typeof BASE_COLS_MAP]
  )
}

export const getGapXClasses = (
  gapX?: Nullable<TBreakpoints | number>
): Nullable<string> => {
  if (!gapX) return null

  if (typeof gapX === 'number')
    return BASE_X_GAP_MAP?.[gapX as keyof typeof BASE_COLS_MAP]

  return clsx(
    BASE_X_GAP_MAP?.[gapX?.initial as keyof typeof BASE_COLS_MAP],
    MD_X_GAP_MAP?.[gapX?.md as keyof typeof BASE_COLS_MAP],
    LG_X_GAP_MAP?.[gapX?.lg as keyof typeof BASE_COLS_MAP],
    XL_X_GAP_MAP?.[gapX?.xl as keyof typeof BASE_COLS_MAP]
  )
}

export const getGapYClasses = (
  gapY?: Nullable<TBreakpoints | number>
): Nullable<string> => {
  if (!gapY) return null

  if (typeof gapY === 'number')
    return BASE_Y_GAP_MAP?.[gapY as keyof typeof BASE_COLS_MAP]

  return clsx(
    BASE_Y_GAP_MAP?.[gapY?.initial as keyof typeof BASE_COLS_MAP],
    MD_Y_GAP_MAP?.[gapY?.md as keyof typeof BASE_COLS_MAP],
    LG_Y_GAP_MAP?.[gapY?.lg as keyof typeof BASE_COLS_MAP],
    XL_Y_GAP_MAP?.[gapY?.xl as keyof typeof BASE_COLS_MAP]
  )
}

export const getClasses = (
  cols: TBreakpoints | number,
  gap: TBreakpoints | number,
  gapX?: Nullable<TBreakpoints | number>,
  gapY?: Nullable<TBreakpoints | number>,
  className?: string
): string => {
  const colClasses = getColClasses(cols)
  const gapClasses = getGapClasses(gap)
  const gapXClasses = getGapXClasses(gapX)
  const gapYClasses = getGapYClasses(gapY)

  return clsx(
    'grid',
    colClasses,
    gapClasses,
    gapXClasses,
    gapYClasses,
    className
  )
}
