import { gql } from '@apollo/client'

export const OFFER_TILE_LINK_CONTAINER_FRAGMENT = gql`
  fragment OfferTileLinkContainerOffer on Offer {
    impressionContent {
      id
      type
      version
    }
    country {
      slug
    }
    brand {
      slug
    }
    slug
  }
`
