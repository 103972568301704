import { gql } from '@apollo/client'

export const OFFER_TILE_EXCLUSIVE_FLAG_FRAGMENT = gql`
  fragment OfferTileExclusiveFlagOffer on Offer {
    baseRedemptionType {
      exclusive
    }
  }
`

export const SB_LOGO_ALT = 'Student Beans'

export const ONLY_ON = 'Only on'
