import { APPLICATIONS } from '@constants/applications'
import { TSnowplowApplication } from '@typings/snowplow'

export const getSnowplowAppId = (
  application: TSnowplowApplication
): string | undefined => {
  if (application === APPLICATIONS.beansidConnect)
    return process.env.NEXT_PUBLIC_CONNECT_SNOWPLOW_APP_ID

  if (application === APPLICATIONS.gradbeans)
    return process.env.NEXT_PUBLIC_GRADBEANS_SNOWPLOW_APP_ID

  return process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID
}
