'use client'

import debounce from 'debounce'
import { useEffect, useState, useRef } from 'react'

import {
  IMPRESSION_LOAD_STORE_NAME,
  IMPRESSION_VIEW_STORE_NAME,
  IMPRESSION_EVENT_NAME
} from '@constants/impression_tracking'
import { hasIntersectionObserverSupport } from '@helpers/intersection_observer'
import { trackKevelViewImpressions } from '@helpers/kevel'
import { trackUnstructEvent } from '@tracker/snowplow'

import { DELAY, SB_ERROR_HANDLER } from './constants'
import { hasData, constructSnowplowEventData } from './helpers'

const useImpressionListener = (handleError) => {
  const [impressionLoadData, setImpressionLoadData] = useState([])
  const [impressionViewData, setImpressionViewData] = useState([])
  const [kevelImpressionViewData, setKevelImpressionViewData] = useState([])

  const impressionLoadStateRef = useRef(impressionLoadData)
  const setImpressionLoadDataRef = (data) => {
    impressionLoadStateRef.current = data
    setImpressionLoadData(data)
  }

  const impressionViewStateRef = useRef(impressionViewData)
  const setImpressionViewDataRef = (data) => {
    impressionViewStateRef.current = data
    setImpressionViewData(data)
  }

  const kevelImpressionViewStateRef = useRef(kevelImpressionViewData)
  const setKevelImpressionViewDataRef = (data) => {
    kevelImpressionViewStateRef.current = data
    setKevelImpressionViewData(data)
  }

  const resetState = () => {
    setImpressionLoadDataRef([])
    setImpressionViewDataRef([])
    setKevelImpressionViewDataRef([])
  }

  const handleSnowplowData = (data) => {
    const batchedEvents = constructSnowplowEventData(data)
    batchedEvents.forEach((batch) => {
      trackUnstructEvent(
        batch?.event?.schema,
        batch?.event?.data,
        batch?.contexts
      )
    })
  }

  const processData = () => {
    handleSnowplowData({
      impressionLoad: impressionLoadStateRef.current,
      impressionView: impressionViewStateRef.current
    })
    trackKevelViewImpressions(kevelImpressionViewStateRef.current)
    resetState()
  }

  const handleStateChange = () => {
    if (
      hasData(impressionLoadStateRef.current) ||
      hasData(impressionViewStateRef.current)
    )
      processData()
  }

  const handleStateChangeDebounced = debounce(handleStateChange, DELAY)

  const handleEvents = (e) => {
    if (e?.detail?.[IMPRESSION_LOAD_STORE_NAME]) {
      setImpressionLoadDataRef([
        ...impressionLoadStateRef.current,
        e.detail[IMPRESSION_LOAD_STORE_NAME]
      ])
    } else if (e?.detail?.[IMPRESSION_VIEW_STORE_NAME]) {
      const { kevelImpressionData, ...snowplowData } =
        e.detail[IMPRESSION_VIEW_STORE_NAME]
      setImpressionViewDataRef([
        ...impressionViewStateRef.current,
        snowplowData
      ])
      if (kevelImpressionData) {
        setKevelImpressionViewDataRef([
          ...kevelImpressionViewStateRef.current,
          kevelImpressionData
        ])
      }
    }
  }

  useEffect(() => {
    if (hasIntersectionObserverSupport()) {
      try {
        document.addEventListener(IMPRESSION_EVENT_NAME, handleEvents)
      } catch (error) {
        handleError?.(error, SB_ERROR_HANDLER)
      }
    }
    return () => {
      document.removeEventListener(IMPRESSION_EVENT_NAME, handleEvents)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      handleStateChangeDebounced()
    } catch (error) {
      handleError?.(error, SB_ERROR_HANDLER)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    impressionLoadData,
    impressionViewData,
    kevelImpressionViewData,
    handleStateChangeDebounced
  ])

  return null
}

export default useImpressionListener
