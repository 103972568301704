export const CCGS = {
  STUDENT: 'student',
  GRADUATE: 'graduate',
  HEALTHCARE: 'healthcare',
  TEACHER: 'teacher',
  MILITARY: 'military',
  LOW_INCOME: 'low-income',
  FIRST_RESPONDER: 'first-responder',
  CHARITY_WORKER: 'charity-worker',
  DISABILITY: 'disability',
  PARENT: 'parent'
} as const

export const VALID_CCGS = [
  CCGS.STUDENT,
  CCGS.GRADUATE,
  CCGS.HEALTHCARE,
  CCGS.TEACHER,
  CCGS.MILITARY,
  CCGS.LOW_INCOME,
  CCGS.FIRST_RESPONDER,
  CCGS.CHARITY_WORKER,
  CCGS.DISABILITY,
  CCGS.PARENT
]

export const ACTIVE_VERIFICATION_STATUS = 'active'
