import { gql } from '@apollo/client'

import { OFFER_TILE_NEW_FRAGMENT } from '@components/offer_tile/constants'

export const KEVEL_TRACKING_FRAGMENT = gql`
  fragment KevelTrackingViewer on KevelOffer {
    trackingUrls
  }
`

export const KEVEL_OFFER_TILE_FRAGMENT = gql`
  fragment KevelOfferTileViewer on KevelAdvertsConnection {
    edges {
      node {
        ... on KevelOffer {
          ...KevelTrackingViewer
          offer {
            ...OfferTileNewOffer
          }
        }
      }
    }
  }
  ${OFFER_TILE_NEW_FRAGMENT}
  ${KEVEL_TRACKING_FRAGMENT}
`

export const TRACK_ADVERT_QUERY = gql`
  mutation TrackAdvertMutation($input: TrackAdvertInput!) {
    trackAdvert(input: $input) {
      eventTypes {
        status
      }
    }
  }
`
